import React from 'react';
import Modal from '../../../components/Modal';
import report from '../../../report/report.pdf';
const media = {
  secTitle: 'Jackson, MS',
  cityModal: true,
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/hYRsoUpOQgA',
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      Jackson is the capital of Mississippi and a Deep South city rooted in its
      racial history. It recently opened the Mississippi Civil Rights Museum.
      Jackson and its neighboring towns have become increasingly segregated over
      the past few decades. Since 1980, Jackson’s white population has
      significantly decreased by about 72,000 people while it has seen growth
      among people of color by about 43,000 people. Economic vitality is unequal
      in Jackson as unemployment is nearly three times higher among black
      workers (11 percent) than it is when compared with their white
      counterparts (4 percent). In Jackson, 7 out of 10 jobs in the city are
      held by workers living outside of the city limits. Jackson will face a
      skills gap if it does not take steps to increase educational attainment
      among black residents. Sixty-four percent of white residents have some
      college education but only 26 percent of black residents do.
    </p>
    <p>
      During our visit, residents told us how this division has created sharp
      inequities in education, housing and public transit. This has been
      exacerbated by suburbanization, a shortage of private investment and brain
      drain leaving the city. Residents shared that Jackson’s history of
      segregation and more recent political polarization make it difficult to
      bring people together to find solutions. Still, we heard frequently about
      the resiliency of this community and its enduring commitment to create a
      more equitable and vibrant city for all. The election of Mayor Chokwe
      Antar Lumumba in 2017 was repeatedly identified as an important change in
      political leadership for Jackson because he is seen as being part of a new
      generation of young leadership that can lead the city forward. IIn
      Jackson, we held individual interviews as well as small group discussions
      with higher education leaders. We visited with city leadership. And, we
      held three focus groups among non-college-educated black women, black men
      and white men. Learn more by reading the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>
  </Modal>
);
